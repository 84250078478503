.contact {
	padding: 40px 30px;

	h2 {
		font-size: 32px;
		margin-bottom: 22px;

		@include breakpoint(tablet){
			font-size: 42px;
			text-align: center;
		}
		@include breakpoint(laptop){
			font-size: 52px;
		}
		@include breakpoint(bigdesktop){
			// font-size: 48px;
		}
	}

	form {
		
		input {
			background: none;
			border: none;
			border-bottom: 1px $black solid;
			color: $black;
					
			&::placeholder {
				color: #666;
			}
		}
		
		.button {
			width: 200px;
			margin: 0 auto;
		    display: block;
			background-color: black;
			border-color: $black;
			color: $white;
		}
	}

	@include breakpoint(phablet) {
		
		form {
			max-width: 70%;
			margin: 0 auto;
			display: grid;
			grid-template-columns: 2fr 1fr;
			grid-gap: 20px;
			
			.button {
				margin: 0;
			}
		}
	}
	@include breakpoint(tablet) {
		
		p {
			text-align: center;
			margin-bottom: 28px;
		}
		
		form {
			width: 100%;
			max-width: 560px;
			padding: 0;

			input {
				padding: 9px;
				height: 44px;
			}
		}
	}
	@include breakpoint(tablet) {
		padding: 120px 0;

		.habitaciones & {
			padding: 60px 0 90px;
		}
	}
}