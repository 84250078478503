#bio_ep {
    max-width: 600px;
    width: 90vw!important;
    height: auto!important;
}
#bio_ep_close {
    background: transparent url('../img/close.png') no-repeat!important;
    width: 54px;
    height: 54px;
    margin: -30px 0 0 -30px;
    transition: transform .2s ease;
    transform: rotate(0turn);
    
    &:hover { 
        transform: rotate(.2turn);
    }   
}
#bio_ep_bg {
    opacity: .9!important; 
}

@include breakpoint(tablet) {
    
    #bio_ep {
        width: auto!important;
    }
}
@include breakpoint(bigdesktop) {
    #bio_ep {
        max-width: 690px;
    }   
}