.we {
    background: url('../img/bg-we.svg');
    background-size: 480px;
    padding-top: 120px;

    article {
        margin-bottom: 60px;
    }
    h2 {
        font-size: 42px;
        margin-bottom: 22px;
    }
    .badge {
        width: 120px;
        margin-top: 40px;
    }

    @include breakpoint(tablet) {   
        padding: 120px 60px;
        
        .wrapper {
            max-width: 1160px;
            margin: 0 auto;
            display: flex;
            align-items: center;
        }
        h2 {
            font-size: 52px;
            max-width: 340px;
            margin-bottom: 60px;
        }
        .content {
            max-width: 450px;
        }
        article, .picture {
            flex: 50%;
            width: calc(50% - 30px);  
            margin: 0;
        }      
        .picture {
            margin-left: 60px;
            position: relative;

            &:after {
                content: "";
                background: rgba(25,32,15,.6)   ;
                width: 40px;
                height: 5px;
                position: absolute;
                bottom: 26px;
                right: 0; 
            }
        }
    }
    @include breakpoint(laptop) {   
        
        .wrapper { 
            max-width: 1280px;
        }        
        h2 {
            font-size: 72px;
            max-width: 480px;
        }
        .content {
            max-width: none;
        }
    }
}

.rooms {
    display: flex;
    flex-direction: column;
    padding: 0;
    
    .button {
        background-color: transparent;
        border: none;

        > span {
            padding-right: 30px;
        }
    }
    
    article {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        padding: 40px 30px 30px 50px;
        width: 100%;
        height: 350px;
        transition: all 1.6s ease-out;

        h2 {
            color: white;
            font-size: 36px;
            text-align: left;
            width: 100%;
            text-shadow: 1px 1px 6px rgba(0,0,0, .4);
        }
        
        &.sr {
            background: 
                linear-gradient( to bottom, rgba(0,0,0, .4), rgba(0,0,0,0), rgba(0,0,0, .4)),
                url('../img/rooms-sr-m.jpg') no-repeat center;
            background-size: cover;
        }
        &.srb {
            background: 
                linear-gradient( to bottom, rgba(0,0,0, .4), rgba(0,0,0,0), rgba(0,0,0, .4)),
                url('../img/rooms-srb-m.jpg') no-repeat center;
            background-size: cover;
        }

        &.dtr {
            background: 
                linear-gradient( to bottom, rgba(0,0,0, .4), rgba(0,0,0,0), rgba(0,0,0, .4)),
                url('../img/rooms-dtr-m.jpg') no-repeat center;
            background-size: cover;
        }

        &.dcr {
            background: 
                linear-gradient( to bottom, rgba(0,0,0, .4), rgba(0,0,0,0), rgba(0,0,0, .4)),
                url('../img/rooms-dcr-m.jpg') no-repeat center;
            background-size: cover;
        }
    }

    @include breakpoint(tablet) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        article {
            height: 50vh;
        }
    }
    @include breakpoint(laptop) {
        
        article {
            padding: 60px 50px 50px 70px;
            
            h2 {
                font-size: 42px;
            }      

            &.sr {
                background-size: 100%;
            }
            &.srb {
                background-size: 100%;
            }
            &.dtr {
                background-size: 100%;
            }
            &.dcr {
                background-size: 100%;
            }  

            &:hover {
                background-size: 110%;
            }              
        }
    }
    @include breakpoint(desktop) {

        article {

            &.sr {
                background-image: 
                    linear-gradient( to bottom, rgba(0,0,0, .4), rgba(0,0,0,0), rgba(0,0,0, .4)),
                    url('../img/rooms-sr-h.jpg');
            }
            &.srb {
                background-image: 
                    linear-gradient( to bottom, rgba(0,0,0, .4), rgba(0,0,0,0), rgba(0,0,0, .4)),
                    url('../img/rooms-srb-h.jpg');
            }
    
            &.dtr {
                background-image: 
                    linear-gradient( to bottom, rgba(0,0,0, .4), rgba(0,0,0,0), rgba(0,0,0, .4)),
                    url('../img/rooms-dtr-h.jpg');
            }
    
            &.dcr {
                background-image: 
                    linear-gradient( to bottom, rgba(0,0,0, .4), rgba(0,0,0,0), rgba(0,0,0, .4)),
                    url('../img/rooms-dcr-h.jpg');
            }            
        }
    }
}

.amenities {
    padding: 40px 30px 30px;

    .content {
        margin-bottom: 52px;

        .button {
            margin-top: 32px;
            background-color: transparent;
            border-color: $black;
            background-image: url('../img/bg-button-b.svg');
            
            span {
                color: $black;
                font-weight: bold;
                padding-right: 36px;
            }

            &:before {
                background: $black;
                color: $white;
            }
        }
    }

    .items {
        line-height: 24px;

        li {
            padding: 6px 0 0 42px;
            min-height: 32px;
            margin-bottom: 12px;

            &.internet {
                background: url('../img/item-internet.svg') no-repeat left 6px;
                background-size: 24px;

                .interna & {
                    background-image: url('../img/item-internet-bn.svg');
                }
            }
            &.rooftop {
                background: url('../img/item-rooftop.svg') no-repeat left 6px;
                background-size: 24px;
            }
            &.counseling {
                background: url('../img/item-counseling.svg') no-repeat left 6px;
                background-size: 24px;
            }
            &.gym {
                background: url('../img/item-gym.svg') no-repeat left 6px;
                background-size: 24px;
            }
            &.relax {
                background: url('../img/item-relax.svg') no-repeat left 6px;
                background-size: 24px;
            }
            &.spa {
                background: url('../img/item-spa.svg') no-repeat left 6px;
                background-size: 24px;
            }
            &.minibar {
                background: url('../img/item-minibar.png') no-repeat left 6px;
                background-size: 24px;
            }
            &.cofre {
                background: url('../img/item-cofre.png') no-repeat left 6px;
                background-size: 24px;
            }
            &.pava {
                background: url('../img/item-pava.png') no-repeat left 6px;
                background-size: 24px;
            }
            &.traba {
                background: url('../img/item-traba.png') no-repeat left 6px;
                background-size: 24px;
            }
            &.desk {
                background: url('../img/item-desk.png') no-repeat left 6px;
                background-size: 24px;
            }
            &.tv {
                background: url('../img/item-tv.png') no-repeat left 6px;
                background-size: 24px;
            }
            &.phone {
                background: url('../img/item-phone.png') no-repeat left 6px;
                background-size: 24px;
            }
            &.blackout {
                background: url('../img/item-blackout.png') no-repeat left 6px;
                background-size: 24px;
            }
            &.parri {
                background: url('../img/item-parri.svg') no-repeat -2px 6px;
                background-size: 34px;
            }
        }
    }
    @include breakpoint(tablet) {
        padding: 60px;
        display: grid;
        grid-template-columns: 38% auto;
        grid-gap: 30px;

        h2 {
            grid-column: 1 / 3;
        }

        .items {
            columns: 2;
        }

        .interna & {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-items: center;
            grid-gap: 0;

            h2 {
                margin-bottom: 6px;
            }

            .items {
                margin-top: 42px;
            }
        }        
    }
    @include breakpoint(laptop) {
        max-width: 1280px;
        margin: 0 auto;
    }
}

.gallery {
    padding: 60px 0 0;

    .swiper-slide {
        
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }        
        &.s50 {
            width: 50%;
        }        
    }
    .swiper-button {
        background: none;
        left: 8%;
        margin-top: -10px;

        .arrow {
            transform: rotate(-.75turn);
            height: 22px;
        }

        &-next {
            left: auto;
            right: 8%;

            .arrow {
                transform: rotate(.75turn);
            }
        }
    }

    @include breakpoint(tablet) {
        
        .swiper-slide {
            opacity: .4;
            transition: .3s ease-in-out;
            
            &-active {
                opacity: 1;
            }
            &.s60 {
                width: 60%;
            }
        }
        .arrow {

            polyline {
                stroke: $black;
            }
        }        
    }    
    @include breakpoint(laptop) {
        
        .swiper-slide {
            
            &.s60 {
                width: 50%;
            }
        }
    }    
}

.experience {
    padding: 0;

    > h2 {
        padding: 40px 30px 0;
    }
        
    .er {
        background: 
            linear-gradient( to bottom, rgba(0,0,0, .4), rgba(0,0,0,0), rgba(0,0,0, .4)),
            url('../img/experiencie-er-m.jpg') no-repeat center;
        background-size: auto 350px;
    }    
    .nr {
        background: 
            linear-gradient( to bottom, rgba(0,0,0, .4), rgba(0,0,0,0), rgba(0,0,0, .4)),
            url('../img/experiencie-nr-m.jpg') no-repeat center;
        background-size: auto 350px;
    }
    
    @include breakpoint(tablet){
        max-width: 1280px;
        margin: 0 auto;
        
        > h2 {
            grid-column: 1 / 3;
            margin-bottom: 42px;
        }
        article {
            height: 46vh;
        }   
        .er {
            background-size: cover;
        }    
        .nr {
            background-size: cover;
        }              
    }
    @include breakpoint(laptop){
        padding-top: 60px;

        article {
            height: 50vh;
        }   
        .er {
            background-image: 
                linear-gradient( to bottom, rgba(0,0,0, .4), rgba(0,0,0,0), rgba(0,0,0, .4)),
                url('../img/experiencie-er-h.jpg');
            background-size: 100%;
        }    
        .nr {
            background-image: 
                linear-gradient( to bottom, rgba(0,0,0, .4), rgba(0,0,0,0), rgba(0,0,0, .4)),
                url('../img/experiencie-nr-h.jpg');
            background-size: 100%;
        }         
    }
    @include breakpoint(bigdesktop){

        article {
            height: 40vh;
        }
    }
}

.clients {
    padding: 0;
   
    h2 {
        padding: 40px 30px 30px;
    }
    h3 {
        font-size: 42px; 
        margin-bottom: 12px;
    }
    .content {
        background: $violet;
        color: $white;
        padding: 10px;
        text-align: center;
    }
    .swiper-container {
        padding: 80px 0;
        background: url('../img/bg-slide-clients-l.svg'), url('../img/bg-slide-clients-r.svg') ;
        background-repeat: no-repeat;
        background-position: 20px 20px, calc(100% - 20px) calc(100% - 30px);
        background-size: 60px;
        width: 100%;
    }
    .swiper-slide {
    padding: 0 60px;
    }
    .swiper-button {
        background: none;
        left: 10px;
        margin-top: -10px;
        width: 44px;
        height: 22px;

        .arrow {
            transform-origin: center;
            transform: rotate(-.5turn);
            height: 22px;
        }

        &-next {
            left: auto;
            right: 10px;

            .arrow {
                transform-origin: center;
                transform: rotate(0turn);
            }
        }
    }    
    .pictures {
        height: 260px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;

        li {
            grid-column: 1 / 2;
            grid-row: 1 / 3;
            background: url('../img/picture-01-m.jpg') no-repeat;
            background-size: cover;
            background-position: center bottom;
            
            &:nth-child(2) {
                grid-column: 2 / 3;
                grid-row: 1 / 2;
                background-image: url('../img/picture-02-m.jpg')
            }
            &:nth-child(3) {
                grid-column: 2 / 3;
                grid-row: 2 / 3;
                background-image: url('../img/picture-03-m.jpg')
            }
        }
    }

    @include breakpoint(tablet) {
        background-color: $violet;

        h2 {
            margin-bottom: 0;
            padding-bottom: 42px;
            background-color: $bg-grey;
        }

        h3 {
            font-size: 48px; 
            margin-bottom: 12px;
        }
        
        .content {
            max-width: 1280px;
            margin: 0 auto;
            padding: 60px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 30px;
        }

        .pictures {
            height: auto;
            grid-gap: 20px;

            li {
                background-image: url('../img/picture-01.jpg');
                
                &:nth-child(2) {
                    background-image: url('../img/picture-02.jpg')
                }
                &:nth-child(3) {
                    background-image: url('../img/picture-03.jpg')
                }
            }            
        }
    }
    @include breakpoint(desktop) {

        h3 {
            font-size: 58px; 
        }
        .content {
            padding: 60px 0;
        }

    }
}

.grid-room {
    padding: 60px 30px 30px;

    .room {
        margin-bottom: 90px;

        &:first-of-type {
            margin-top: 90px;
        }
    }

    .picture {
        margin: 0 -30px;
    }

    .content {
        padding-top: 40px;

        h2 {
            font-size: 36px;
            margin-bottom: 36px;
        }
        .button { 
            margin-top: 36px;
            border-color: $violet; 
            background: $violet;
        
            &:before, & > span {
                font-size: 16px;
            }    
            
            & > span {
                color: $white;
            }
        
            &:before {
                color: $grey;
                background: $white;
            }
        }
    }

    @include breakpoint(tablet) {
        padding: 60px 0 30px;
        max-width: 1380px;
        margin: 0 auto;
        
        > p {
            max-width: 70%;
            margin: 0 auto 18px;
            text-align: center;
        }

        .room {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin: 0;

            &:nth-of-type(even) {
                
                .picture {
                    grid-column: 2 / 3;                    
                }                
                .content {
                    grid-column: 1 / 2;
                    grid-row: 1 / 2 ;
                }
            }

            // &:hover {

            //     img {
            //         transform: scale(1.1);
            //     }
            // }    

            .picture {
                margin: 0;
            }                    
        }

        .picture {
            overflow: hidden;
            
            img {
                height: 540px;
                object-fit: cover;
                object-position: center;
                transition: all .6s ease;
            }
        }
        
        .content {
            padding: 40px 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    @include breakpoint(laptop) {

        .picture {
            
            img {
                height: 640px;
            }
        }
        .content {
            padding: 40px 60px;
            
            h2 {
                font-size: 52px;
            }
        }
        .room {

            &:first-of-type {
                margin-top: 68px;
            }        
        }
    }
}

.info {
    max-width: 1280px;
    margin: 0 auto;
    
    .button {
        max-width: 300px;
        margin-top: 32px;
        background-color: $violet;
        border-color: $violet;
        width: auto;
    
        > span {
            padding-right: 40px;
        }        

        &:before {
            background: $white;
            color: $violet;
        }
    }

    @include breakpoint(tablet) {
        text-align: center;

        .button {
            margin: 32px auto 0;
        }
    }
}

.services {
    max-width: 1280px;
    margin: 0 auto;

    .items {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 30px;
        margin-top: 60px;
        text-align: center;
    }
    .icon {
        width: 80px;
        margin: 0 auto 22px;
        filter: drop-shadow(4px 4px 4px rgba(0,0,0,.4));
    }

    @include breakpoint(phablet) {

        .items {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    @include breakpoint(tablet) {

        .items {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            li {
                flex: 200px 0;
            }

            text-align: center;
        }
    }
}

.places {
    padding: 60px 30px 30px;
    max-width: 1280px;
    margin: 0 auto;

    .items {
        margin-top: 62px;
        
    }
    article {
        margin-bottom: 62px;
        
        h2 {
            color: $white;
            font-size: 32px;
            background: url('../img/bg-interes-gastronomia.jpg') no-repeat;
            background-size: cover;
            padding: 62px 42px;
            margin-bottom: 42px;
        }
        a {
            color: $violet;
            font-weight: 800;
            border-bottom: 2px $violet solid;
            padding-bottom: 2px;

            &:hover {
                border-color: transparent;
            }
        }


        &.interes {
            
            h2 {
                background-image: url('../img/bg-interes-interes.jpg');
            }
        }
        &.museos {
            
            h2 {
                background-image: url('../img/bg-interes-museos.jpg');
            }
        }
        &.compras {
            
            h2 {
                background-image: url('../img/bg-interes-compras.jpg');
            }
        }
        &.agenda {
            
            h2 {
                background-image: url('../img/bg-interes-agenda.jpg');
            }
        }        
    }

    @include breakpoint(tablet) {

        .items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        article {
            flex: 49% 0;
        }
    }
}

.promos {
    max-width: 1280px;
    margin: 0 auto;

    h2 {
        color: $white;
        font-size: 32px;
    }        
    .top {
        margin-bottom: 42px;
        background: url('../img/bg-promo-noche.jpg') no-repeat;
        background-size: cover;
        padding: 62px 42px;
        
    }
    .promo {
        margin-bottom: 72px;

        &:last-child {
            
            .top {
                background-image: url('../img/bg-promo-relax.jpg');
            }
        }
    }
    .items {
        margin: 22px 0;
        
        li {
            background: url('../img/bg-tilde.svg') no-repeat center left;
            background-size: 18px;
            padding-left: 28px;
            line-height: 24px;
            margin-bottom: 6px;
        }
    }
    .button {
        margin-top: 42px;
        background-color: $violet;
        border-color: $violet;

        > span {
            color: $white;
            font-weight: bold;
            padding-right: 40px;
        }

        &:before {
            background: $white;
            color: $violet;
        }        
    }

    @include breakpoint(tablet) {

        h2 {
            font-size: 52px;
            text-align: center;
            margin: auto;
        }
        .promo {
            display: flex;  
            justify-content: space-between;
            
            &:first-child {
                
                .top { order: 2; }
            }
        }
        .top {
            flex: 48% 0;
            margin: 0;
            display: flex;
        }
        .content {
            flex: 48% 0;
            padding: 42px 0;
        }
        .items {
            columns: 2 auto;
        }
    }
}

.options {
    max-width: 1280px;
    margin: 0 auto;

    .items {
        margin-top: 60px;

        h3 {
            font-family: $font-base;
            font-size: 24px;
            font-weight: 400;
        }
        // .reservar {
        //     background: $violet;
        //     color: $white;
        //     width: 160px;
        //     border: 2px $violet solid;
        //     margin-top: 22px;
        //     padding: 10px 0;
        //     cursor: pointer;

        //     &:hover {
        //         background-color: $white;
        //         color: $violet;
        //     }
        // }

        .button { 
            border-color: $violet; 
            background: $violet;
            margin-top: 22px;
        
            &:before, & > span {
                font-size: 16px;
            }    
            
            & > span {
                color: $white;
            }
        
            &:before {
                color: $grey;
                background: $white;
            }
        }

        li {
            padding-top: 150px;
            margin-bottom: 42px;
            overflow: hidden;
            border-radius: 8px;
            box-shadow: 0px 0px 10px rgba(0,0,0, .4);
        }
        .content {
            background-color: $white;
            padding: 30px;
            min-height: 140px;
        }
        .studios {
            background: url('../img/apartamento-studios.jpg') no-repeat;
            background-size: contain;
            
            .abasto & {
                background-image: url('../img/abasto-studios.jpg');
            }
            .callao & {
                background-image: url('../img/callao-studios.jpg');
            }
        }
        .suites {
            background: url('../img/apartamento-suites.jpg') no-repeat;
            background-size: contain;

            .callao & {
                background-image: url('../img/callao-suites.jpg');
            }
        }
    }

    @include breakpoint(phablet) {
        
        .items {
            display: flex;
            justify-content: space-between;
            
            li {
                flex: 48% 0;
            }

            .abasto & {
                justify-content: center;
            }
        }
    }
    @include breakpoint(tablet) {
        
        .items {

            h3 {
                font-size: 20px;
            }
            li {    
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                padding: 0;
            }
            .content {
                grid-column: 3 / 4;
                height: 280px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
}

.spaces {
    // max-width: 1280px;
    margin: 0 auto;
    padding-bottom: 0;

    h3 {
        font-family: $font-base;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 16px;
    }
    .content {
        padding-top: 20px;
    }
    .items {
        line-height: 1.6;
        padding-left: 16px;
        
        li {
            list-style: disc;
        }
    }
    .grid-spaces {
        margin-top: 60px;
    }
    .item {
        margin-bottom: 62px;
    }
    .flag {
        font-size: 14px;
        display: block;
        background-color: $black;
        padding: 10px 14px;
        color: $white;
    }
    // .swiper-button {
    //     background: none;
    //     left: 8%;
    //     margin-top: -10px;

    //     .arrow {
    //         transform: rotate(-.75turn);
    //         height: 22px;
    //     }
    //     &-next {
    //         left: auto;
    //         right: 8%;

    //         .arrow {
    //             transform: rotate(.75turn);
    //         }
    //     }
    // }    

    @include breakpoint(phablet) {    
        padding-bottom: 30px;
        
        .grid-spaces {
            display: flex;
            flex-wrap: wrap;            
            justify-content: space-between;
        }
        .item {
            width: 49%;

            &-group {
                width: 100%;
                display: flex;
                flex-wrap: wrap;            
                justify-content: center;
            }
        }
        .abasto &, .callao &, .arenales & {
            
            .grid-spaces {
                justify-content: center;
            }
            .item {
                margin: 0 15px 62px;
                width: 30%;
            }            
        }

    }
    @include breakpoint(tablet) {    

        h2 + p {
            text-align: center;
            max-width: 720px;
            margin: 0 auto;
        }   
        .item {
            width: 32%;
        }
    }
    @include breakpoint(desktop) {    
        // max-width: 1480px;
    }        
    @include breakpoint(bigdesktop) {    
        // max-width: 1600px;
    }        
}

.menu-hoteles {
    max-width: 1280px;
    margin: 0 auto;

    h2 + p {
        max-width: 720px;
        margin: 0 auto;
    }    

    .hoteles {
        margin-top: 48px;

        a {
            display: flex;
            height: 200px;
            background: 
                linear-gradient( to bottom, rgba(0,0,0,.6),rgba(255,255,255,.5)),
                url('../img/bg-hoteles-arenales.jpg') no-repeat;
            background-size: cover;
            margin-bottom: 20px;
            border: none;
            border-radius: 10px;

            &.callao {
                background-image: linear-gradient( to bottom, rgba(0,0,0,.6),rgba(255,255,255,.5)),
                url('../img/bg-hoteles-callao.jpg');
            }
            &.abasto {
                background-image: linear-gradient( to bottom, rgba(0,0,0,.6),rgba(255,255,255,.5)),
                url('../img/bg-hoteles-abasto.jpg');
            }

            .logo {
                margin: auto;
                height: 60px;
            }

            @include breakpoint(laptop) {
                background-size: 400px;
                background-position: center;

                &:hover { 
                    background-size: 460px;
                }
            }
        }
    }

    @include breakpoint(phablet) {

        .hoteles {
            display: flex;
            justify-content: center;
            grid-gap: 20px;           

            a {
                margin: 0;
                width: 390px;
            }
        }
    }
    @include breakpoint(laptop) {
        margin-bottom: 60px;
    }
}

.discover {
    background: $violet;
    color: $white;
    padding-bottom: 60px;
    max-width: 1280px;
    margin: 0 auto;

    .button { 
        border-color: $black; 
        background: $black;
        margin-top: 38px;
    
        &:before, & > span {
            font-size: 16px;
        }    
        
        & > span {
            color: $white;
        }
    
        &:before {
            color: $black;
            background: $white;
        }
    }
    // .reservar {
    //     background: $black;
    //     color: $white;
    //     width: 160px;
    //     border: 2px $black solid;
    //     margin-top: 38px;
    //     padding: 10px 0;
    //     cursor: pointer;

    //     &:hover {
    //         background-color: $white;
    //         color: $black;
    //     }
    // }

    @include breakpoint(tablet) {    
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        h2 {
            grid-column: 1 / 3;
            text-align: left;

            span {
                display: block;
            }
        }
        .content {
            grid-column: 1 / 3;
            grid-row: 2 / 3;
        }
        .button {
            grid-column: 3 / 4;
            grid-row: 1 / 3;
            align-self: center;
            justify-self: end;
        }
    }

    @include breakpoint(laptop) {   
        position: relative;
        
        &:before {
            content: '';
            background-color: $violet;
            height: 100%;
            width: 100%;
            position: absolute;
            left: -100%;
        }
        &:after {
            content: '';
            background-color: $violet;
            height: 100%;
            width: 100%;
            position: absolute;
            right: -100%;
        }
    }
}

.testimonials {
    background: $bg-grey-d;

    h4 {
        font-size: 18px;
    }
    .stars {
        display: flex;
        margin: 22px 0 12px;

        li {
            background: url('../img/bg-star.svg') no-repeat;
            background-size: contain;
            height: 26px;
            width: 26px;
            margin-right: 6px;
        }
    }
    .user-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        position: relative;

        &:before {
            content: '';
            background-color: $bg-grey-d;
            width: 60px;
            height: 2px;
            position: absolute;
            top: 50%;
            left: 114px;
        }

        .user-profile {
            width: 100px;
            border-radius: 100%;
            position: relative;
            z-index: 2;
        }
        .icon {
            height: 60px;
        }
    }
    .slide-user {
        padding-bottom: 56px;
        max-width: 1280px;
        margin: 0 auto;
    }
    .swiper-slide {
        background: $white;      
        padding: 30px;
        height: auto;
    }
    .swiper-button {
        background: none;
        left: 0;
        margin-top: -10px;
        height: 22px;
        width: 44px;
        top: auto;
        bottom: 0px;

        .arrow {
            transform: rotate(-.5turn);
            height: 22px;
            width: 44px;
            fill: $black;
        }

        &-next {
            left: auto;
            right: 0;

            .arrow {
                transform: rotate(1turn);
            }
        }
    }
    @include breakpoint(laptop) {
        padding: 60px 0;

        .swiper-button {
            background-color: $bg-grey-d;
            top: 0;
            bottom: auto;
            height: 100%;
            margin: 0;
            width: 80px;
            display: flex;

            .arrow {
                margin: auto;
            }
        }
        .slide-user {
            padding: 0 80px;
        }
        .user-top {
    
            &:before {
                width: 116px;
            }
        }        
    }
}