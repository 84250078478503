// Fonts
$font-title: 'Oswald', sans-serif;
$font-base:	'Raleway', sans-serif;

// Colors
$white: #fff;
$black: #000;
$grey: #333;
$violet: #522d6d;
$bg-form: rgba(0,0,0,0.6);
$bg-grey: #f2f2f2;
$bg-grey-d: #CCCCCC;