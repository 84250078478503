video {
	object-fit: cover;
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	display: none;
	z-index: -1;

	@include breakpoint(tablet) {
		display: block;
	}
}

.hero {
	background: 
		linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
		url('../img/hero-m.jpg') no-repeat;
	background-size: cover;
	height: 100vh;
	display: flex;
	align-items: flex-end;
	position: relative;
    padding: 80px 0;

	h1 {
		font-size: 42px;
		letter-spacing: .8px;
		color: $white;
		margin: auto;
		text-shadow: 0px 0 18px rgba(0,0,0, .4);
		animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	}	

	&:before {
		content: '';
		width: 100%;
		height: 100%;
		background: linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8));
		position: absolute;
		top: 0;
		left: 0;
	}

	.button { 
		display: block;
		border-color: $violet; 
		background: $violet;
		margin: 0 auto;
		width: 180px;
	
		&:before, & > span {
			font-size: 16px;
			padding: 12px 40px;
		}    
		
		& > span {
			color: $white;
		}
	
		&:before {
			color: $grey;
			background: $white;
		}
	}

	.reservation_form {
		width: 100%;
		padding: 30px;
		position: relative;
		z-index: 2;
	}
	
	.gracias & {
		background-image: 
			linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
			url('../img/hero-turismo-m.jpg');
		text-align: center;		
	}	
	.interna & {

		&.habitaciones {
			background: 
			linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
			url('../img/hero-m-hab.jpg') no-repeat;
			background-size: cover;		

			&:before {
				content: none;
			}		
		}
	}
	.turismo & {
		background: 
		linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
		url('../img/hero-turismo-m.jpg') no-repeat;
		background-size: cover;		

		&:before {
			content: none;
		}
	}
	&.promociones {
		background: 
		linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
		url('../img/hero-promociones-m.jpg') no-repeat;
		background-size: cover;		

		&:before {
			content: none;
		}
	}
	&.arenales {
		background: 
		linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
		url('../img/hero-arenales-m.jpg') no-repeat;
		background-size: cover;			
	}
	&.abasto {
		background: 
		linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
		url('../img/hero-abasto-m.jpg') no-repeat;
		background-size: cover;		
	}
	&.callao {
		background: 
		linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
		url('../img/hero-callao-m.jpg') no-repeat;
		background-size: cover;		
	}

	@include breakpoint(phablet) {
		
		h1 {
			font-size: 68px;
		}
		
		.reservation_form {
		
			.booking-mask {
				// max-width: 300px;
				margin: 0 auto;
			}
		}

		.gracias & {
			background-image: 
				linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
				url('../img/hero-turismo-m.jpg');
			text-align: center;		

			h1 {
				font-size: 42px;
			}
		}		
		
		.reservation_form .booking-mask {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			
			.date {
				flex: 0 0 20%;
				padding-right: 10px;
				
				&.in, &.out {
					// flex-grow: 1;
					flex: 2 1 auto;
				}
			}
			
			.hb-form-field-input {
				flex: 0 0 20%;
				flex-grow: 1
			}
	
			input, select {
				margin: 0;
				height: 46px;
			}
			
			.send-button {
				flex: 100% 0 0;
				margin-top: 10px;
			}
		}		
	}
	@include breakpoint(tablet) {
		background: none;
		align-items: flex-end;
		justify-content: center;
		padding-bottom: 0;

		.reservation_form {
			width: 840px;
			padding: 0 0 60px;
			margin: 0 auto;
			
			.booking-mask {
				
				.date, .hb-form-field-input {
					flex: 25% 1 0;
				}
		
				.send-button {
					flex: initial;
					margin: 0;
					padding-left: 10px;
				}
			}		
		}

		.button {
			margin: 0;
		}		

		.interna & {

			&.habitaciones {
				padding-top: 110px;
				background-image: linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
				url('../img/hero-hab.jpg');
			}
			&.arenales, &.abasto, &.callao {
				background: none;
			}			
		}
		.turismo & {
			padding-top: 110px;
			background-image: linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
			url('../img/hero-turismo.jpg');
		}
		&.promociones {
			padding-top: 110px;
			background-image: linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
			url('../img/hero-promociones.jpg');
		}

		.gracias & {
			background-image: 
				linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
				url('../img/hero-turismo.jpg');
		}		
	}
	@include breakpoint(laptop) {
		
		.reservation_form {
	
			.booking-mask {
				max-width: 1280px;
				margin: auto;
				
				.date {
					
					&.in, &.out {
						// flex-grow: 0;
					}
				}			
			}
		}
	}
	@include breakpoint(desktop) {

		h1 {
			font-size: 92px;
		}
		.reservation_form {
			padding: 0 0 100px;
		}
		.interna & {
			
			&.habitaciones {
				padding-top: 110px;
				background-image: linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
				url('../img/hero-h-hab.jpg');
			}
		}
		.turismo & {
			padding-top: 110px;
			background-image: linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
			url('../img/hero-turismo-h.jpg');
		}
		.gracias & {
			background-image: 
				linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
				url('../img/hero-turismo-h.jpg');
		}				
	}
}


/* ----------------------------------------------
 * Generated by Animista on 2021-6-17 11:30:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}