*, *:before, *:after { box-sizing: border-box; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
img {
	display: block;
	width: 100%;
}
h1, h2, h3, h4, h5, h6 {
	font-family: $font-title;
	font-weight: 600;
	line-height: 1.4;
}
ul, li {
	list-style: none;
}
sup {
	font-size: .8em;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, picture, svg {
	display: block;
}
body {
	font: 400 16px $font-base;
	color: $black;
	line-height: 1;
	background: $bg-grey;
	position: relative;
	padding-bottom: 160px;

	&.turismo {
		background: $white;
	}

	&:before {
		content: "";
		background: linear-gradient(to top, rgba(0,0,0,.4), transparent);
		width: 100%;
		height: 16vh;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 2;
		opacity: .6;
	}
}
main {
	overflow: hidden;

	> section {
		padding: 60px 30px 30px;

		> h2 {
			font-size: 32px;
			margin-bottom: 22px;

			@include breakpoint(tablet){
				font-size: 42px;
				text-align: center;
			}
			@include breakpoint(laptop){
				font-size: 62px;
			}
			@include breakpoint(bigdesktop){
				// font-size: 48px;
			}
		}
	}
	a {
		color: $violet;
		border-bottom: 1px $violet solid;
	
		&:hover {
			border-color: transparent;
		}
	}	
}
a {
	text-decoration: none;
	transition: all .4s ease-out;
	color: $black
}
p {
	line-height: 24px;
    margin-bottom: 12px;
}
strong {
	font-weight: 600;
}
input, select {
	width: 100%;	
	background: $bg-form url('../img/bg-select.svg') no-repeat right 20px center;
	background-size: 10px;
	padding: 14px;
	margin-bottom: 8px;
	border: 2px rgba(255,255,255,.4) solid; 
	color: $white;
	font-family: $font-base;
	font-size: 14px;
	transition: all .2s ease-in-out;
	
	&::placeholder {  
		color: $white;
	}	
	&::-moz-placeholder {
	  opacity: 1;
	}
	&:focus {
		
		&::placeholder {
			color: $white;
		} 
	}
	&.error {
		border-color: red;
	}
}
select {
	appearance: none;
	padding: 14px 42px 14px 14px;

	@include breakpoint(tablet) {
		padding: 0 42px 0 14px;
	}
}
.button { 
	font-size: 14px;
	overflow: hidden;
	padding: 0;
	width: 100%;
	height: 46px;
	border: 2px rgba(255,255,255,.4) solid; 
	border-radius: 0;  
	background: $bg-form url('../img/bg-button.svg') no-repeat right 20px center;
	background-size: 26px;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;

	&:before, & > span {
		padding: 14px;
		transition: transform 0.3s;
		transition-timing-function: ease;
		transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	}    
	
	& > span {
	    display: block;
	    vertical-align: middle;
	    color: $white;
	}

	&:before {
		color: $grey;
		content: attr(data-text);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $white;
		transform: translate3d(-100%, 0, 0);	    

	}

	&:hover {

		&:before {
		    transform: translate3d(0, 0, 0);
		}
		& > span {
			transform: translate3d(0, 100%, 0);
		}
	}
}

a.button { 
	display: block;
	text-align: center;
	width: 160px;
}

.habitaciones {
	
	main {
		background: $white url('../img/bg-we.svg');
    	background-size: 480px;
	}
}

.promociones {
	
	+ main {
		background: $white;
	}
}

.whatsapp-button {
	margin-top: 28px;
	display: inline-block;
	border: none;
	
	svg {
		width: 62px;
	}

	@include breakpoint(laptop) {
	
		svg {
			width: 42px;
		}	
	}
}

.grecaptcha-badge {
	opacity: 0;
}

.swiper-container {

	.swiper-button {
		background: none;
		left: 8%;
		margin-top: -10px;

		.arrow {
			transform: rotate(-.75turn);
			height: 22px;
		}
		&-next {
			left: auto;
			right: 8%;

			.arrow {
				transform: rotate(.75turn);
			}
		}
	}
}