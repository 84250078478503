.footer {
	background: #ccc url('../img/bg-we.svg');
    background-size: 480px;
	padding: 40px 30px 30px;

	.logo-footer {
		margin: 0 auto 32px;
		width: 220px;
	}

	h4 {
		font-size: 20px;
		margin-bottom: 16px;
	}

	.list-hotels {
		margin-bottom: 42px;

		li {
			margin-bottom: 8px;

			span {
				display: block;
				line-height: 2;
				font-weight: bold;
			}
		}
	}

	.booking {
		
		li {
			line-height: 22px;
			margin-bottom: 8px;

			.mail {
				display: block;
				background: url('../img/bg-mail.svg') no-repeat;
				background-size: 22px;
				padding-left: 32px;
			}
			.web {
				display: block;
				background: url('../img/bg-web.svg') no-repeat;
				background-size: 22px;
				padding-left: 32px;
			}
			.tel {
				display: block;
				background: url('../img/bg-tel.svg') no-repeat;
				background-size: 22px;
				padding-left: 32px;
			}
			.wsp {
				display: block;
				background: url('../img/bg-wsp.svg') no-repeat;
				background-size: 22px;
				padding-left: 32px;
			}
		}
	}
	
	&_location {
		background-color: $violet;
		padding: 20px;
		color: $white;
		text-align: center;

		span {
			padding-left: 20px;
			background: url('../img/bg-location.svg') no-repeat;
		}
	}

	&_burakko {
		text-align: center;
		color: $grey;
		font-weight: bold;
		display: block;
		// transition: color .3s ease;
		
	}
	
	a:hover {
		color: $violet;
	}

	@include breakpoint(tablet) {
		padding: 60px 60px 40px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		align-items: center;

		.logo-footer {
			margin: 0;
		}

		.list-hotels {
			margin: 0;
			justify-self: center;

			li:last-child {
				margin: 0;
			}
		}

		.booking {
			margin: 0;
			justify-self: end;
			align-self: start;
		}
	} 
}

.button-reservar {
	position: fixed;
	z-index: 2;
	bottom: 20px;
	left: 50%;
	transform: translate(-50%);
	background: $violet;
	color: white;
	font-size: 14px;
	font-weight: bold;
	height: 90px;
	width: 90px;
	border-radius: 100px;
	border: 2px $violet solid;
	cursor: pointer;
	transition: all .3s ease-out;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	pointer-events: none;
	
	@include breakpoint(tablet) {
		height: 120px;
		width: 120px;

		&:hover {
			background-color: $white;
			color: $violet;
		}
	}

	&.show {
		opacity: 1;
		pointer-events: all;
	}
}