.header {
    width: 100%;
	position: absolute;
	z-index: 10;
	padding: 18px 22px;
	transition: all .3s ease-in-out;
	
	.open-menu & {
		box-shadow: none;
	}
	
	.content {
		display: flex;
		align-items: center;
		
		.logo {
			overflow: hidden;
			margin: 0 10px 0 30px;

			.hotel & { display: none; }

			@include breakpoint(mobile) {
				margin-left: 22px;
				
				.hotel & { display: block; }
			}
			@include breakpoint(phablet) {
				margin: 0 0 0 22px;

			}
		}
	}

	.logo-hotel {
		width: 100%;
		height: 40px;
		
		> * {
			transition: fill .6s ease .1s;
		}

		.open-menu & > * {
			fill: $black;
		}		
	}

	&__toggle {
	    width: 27px;
	}

	&__toggle-line {
	    display: block;
	    background: $white;
	    width: 100%;
	    height: 4px;
	    margin-bottom: 4px;
	    opacity: 1; 
	    transition: opacity 600ms ease 200ms, top 300ms ease, left 300ms ease, background 300ms ease, transform 300ms ease 450ms;    
		
		&:nth-child(1) {
		    transform-origin: center;
		}
		&:nth-child(3) {
		    transform-origin: center;
		}
	}    
	
	&__toggle-hotels {
		border: 3px $white solid;
		padding: 6px 12px;
		color: $white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 120px;
		
		span {
			font-weight: 600;
		}

		.icon {
			fill: $white;
			width: 10px;
			height: 7px;
			transition: transform 600ms ease 200ms;
		}

		.open-menu & {
			pointer-events: none;
		}

		.open-menu-h & {
			background: $violet;

			.icon {
				transform: rotate(.5turn);
			}
		}
	}

	.buttons { 
		display: none;
	}

	&.fixed {
		top: 0;
		position: fixed;
		z-index: 11;
		animation: headerFix .5s ease-out;	
		background-color: $violet;
	}

	&.is-active {
	    position: fixed;
	    z-index: 11;
		// background: rgba(255, 255, 255, .95);

		.header__toggle-line {

			&:nth-child(1) {
			    transform: translateY(8px) rotate(45deg);
			    background: $black;
			}
			&:nth-child(2) {
			    background: $black;
			    opacity: 0;
			}
			&:nth-child(3) {
			    transform: translateY(-8px) rotate(-45deg);
			    background: $black;
			}
		}		
	}

	.interna & {
		background: white;
		padding-bottom: 16px;

		.logo-hotel {
	
			> * {
				fill: $violet;
			}		
		}		

		&__toggle-line {
			background: $black;
		}		

		&__toggle-hotels {
			border-color: $black;
			color: $black;
	
			.icon {
				fill: $black;
			}
		}

		// &.fixed {
		// 	background-color: $white;
		// }
	}
	
	.interna.open-menu-h & {
		
		&__toggle-hotels {
			background: $white;
		}
	}			

	@include breakpoint(phablet) {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.content {
			align-items: center;
			justify-content: space-between;
			flex: 2;
		}

		.logo-hotel {
			height: 76px;
		}		

		.buttons { 
			flex: 1;
			display: flex;
			justify-content: flex-end;
		}

		&__toggle-hotels {
			width: 180px;
		}

		.interna & {
			padding: 26px 30px;
			
			.content {
				padding-right: 12%;
			}
			
			.logo-hotel {
				height: 48px;
			}
		}		
	}
	@include breakpoint(tablet) {
		padding: 6vh 10vh 0;
		
		.content {
			align-items: flex-start;
			
			.header__toggle {
				margin-top: 6vh;
			}		
			.logo {		
				margin: 0px;
			}
		}

		.logo-hotel {
			height: 100px;
		}
				
		&__toggle-hotels {
			padding: 14px 26px;
			width: 210px;
			flex: 210px 0 0;	
		}

		.interna & {
			padding: 30px;
		
			.content {
				align-items: center;
				
				.header__toggle {
					margin: 0 0 0 10px;
				}	

				.logo-hotel {
					margin-right: 10%;
				}
			}			
		}		
	}
	@include breakpoint(laptop) {
		
		.content {
			
			.header__toggle {
				margin-top: 8vh;
			}	
		}
		
		.logo-hotel {
			height: 18vh;
		}		
	}
	@include breakpoint(desktop) {

		.interna & {
			
			.content {
				padding-right: 16%;
			}
		}
	}		
}

.hotels {
	height: auto;
	padding: 20px;
	opacity: 0;
	background: $white;
	position: absolute;
	top: 80px;
	right: 100%;
	z-index: 11;
	transition: opacity 600ms ease, right 100ms ease 600ms;
	
	.menu-list {
	    list-style: none;
	    margin: 0;
	    padding: 0;
	    text-align: center;

		li {
		    font-size: 18px; 
		    font-weight: 600;
		    border-bottom: 1px $grey solid;  

			a {
			    line-height: 38px;
			    color: $black;
			    
			    &:hover {
			    	color: $violet;
			    }
			}

			span {
				line-height: 38px;
			}

			&:last-child {
				border: none;
			} 
		}
	}	

	&:before {
		content: "";
		border-color: $white transparent;
		border-style: solid;
		border-width: 0px 10px 10px 10px;
		height: 0px;
		width: 0px;			
		position: absolute;
		top: -10px;
		left: 60%;
	}	
	
	&.is-visible {
	    right: 30px;
	    opacity: 1;
	    transition: top 100ms ease, opacity 600ms ease 400ms;
	}		
	
	.interna & {
		top: 90px;

		&:before {
			// border-color: #FFCD00 transparent;
		}		

		.menu-list {
	
			li {
				// border-bottom-color: $white;  
	
				a {
					// color: $white;
					
					&:hover {
						color: $violet;
					}
				}
			}
		}

		&.is-visible {
			right: 30px;
			// background-color: #FFCD00;
			box-shadow: 0 0 10px rgba(0,0,0,.4);
		}
	}

	@include breakpoint(tablet) {
		top: calc(6vh + 110px);
		width: 180px;
		padding: 10px 20px;		
		
		.interna & {
			// top: calc(6vh + 90px);
		}

		&.is-visible {
			right: 10vh;
		}
	}
}

.menu {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 11;
    width: 100%;
    height: 100%;
    opacity: 0;
    padding: 80px 30px 50px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
    background: rgba(255, 255, 255, .95);
    transition: opacity 400ms ease, left 100ms ease 600ms;

	&.is-visible {
	    left: 0;
	    opacity: 1;
	    transition: left 100ms ease, opacity 400ms ease 200ms;
	    // overflow: scroll;
	}
	
	.menu-inner {
	    margin: 0;
		
		.menu-list {
		    list-style: none;
		    margin: 0;
		    padding: 0;
		    
			li {

				a {
				    font-size: 28px; 
				    font-weight: 600;  
				    line-height: 50px;
				    color: $black;
				    
				    &:hover {
				    	color: $black;
				    }
				}

			}

			&.sedes {
				margin: 10px 0;
				
				li a {
					color: $violet;
					font-size: 24px;
				}
			}
		}

		.menu-legal {
			font-size: 16px;
			margin: 10px 0;
			
			a, span {
				color: $black;
				transition: color .6s ease;
			}

			a:hover {
				color: $black;
			}

			span {
				margin: 0 12px;
			}

			em {
				font-style: normal;
				color: $black;
			}
		}

		.menu-social {
			display: flex;
			width: 80px;
			justify-content: space-between;			
			margin-top: 20px;
			
			.icon {
				width: 23px;
				height: 23px;
				fill: $black;
				transition: fill .6s ease;
				
				&:hover {
					fill: $violet;					
				}
			}
		}
	}

	// .gracias & { display: none;}

	@include breakpoint(mobilelandscape) {
		
		.menu-inner {
			display: grid;
			grid-template-columns: 40% 60%;
			
			.menu-list {
				grid-row: 1 / 3;
				align-self: end;
				
				li {

					a {
					    font-size: 26px; 
					    line-height: 38px;
					}
				}				
			}
			.menu-legal {
				grid-column: 2 / 3;
				grid-row: 1 / 2;
				align-self: end;
				margin: 0 0 20px 0;
			}
			.menu-social {
				grid-column: 2 / 3;
				grid-row: 2 / 3;
				align-self: end;
			}
		}
	}

	@include breakpoint(phablet) {

		.menu-inner {
			
			.menu-list {
				
				&.sedes {
					display: none;
				}
			}
		}
	}
	@include breakpoint(tablet) {
		padding: 0px 120px 76px;
		
		.menu-inner {
			
			.menu-legal {
				margin: 60px 0;
			}

			.menu-social {
				width: 120px;
				
				.icon {
					width: 38px;
					height: 38px;
				}
			}			
		}			
		
		&.fixed {
		    top: 0;
			padding: 13px 20px;
			background: $white;
			position: fixed;
			animation: headerFix .5s ease-out;
		}
	}
	@include breakpoint(laptop) {	

		.menu-inner {
			
			.menu-list {
				
				li {
					a {
					    &:hover {
							color: #FFCD00;
						}
					}
				}
			}			
			
			.menu-legal {
				margin: 6vh 0;
			}

			// .menu-list + .menu-social {
			// 	margin-top: 6vh;
			// }
		}
	}
	@include breakpoint(desktop) {

		.menu-inner {
			
			.menu-list {
				
				li {
					a {
					    font-size: 48px;
					    line-height: 70px;
					}
				}
			}
		}
	}
}

@keyframes headerFix {
	0%   { top: -100px; }
	100% { top: 0; }
}